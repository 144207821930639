import { NULL_LINK } from '../constants';

function isValidFullSlug(link) {
  return typeof link == 'string' && link[0] !== '/';
}

function parseStoryblokLink(link) {
  if (!parseStoryblokLink.isValidUrl(link)) {
    return NULL_LINK;
  }

  if (link.url) {
    return link.url;
  }

  if (link.email) {
    return 'mailto:' + link.email;
  }

  if (link.cached_url) {
    return '/' + link.cached_url;
  }

  return `/${link}`;
}

parseStoryblokLink.isValidUrl = function (link) {
  return (
    link && (link.url || link.email || link.cached_url || isValidFullSlug(link))
  );
};

export default parseStoryblokLink;
